document.addEventListener("turbolinks:load", function() {
  // Carousel
  $('#homeCarousel').carousel({
    ride: 'carousel'
  })

  // Custom plugin to handle toggling element classes
  $('[data-custom-toggle]').on('click', function(e) {
    e.preventDefault();

    var target = $(this).data('custom-target');
    var className = 'active';

    if ($(this).data('custom-class')) {
      className = $(this).data('custom-class')
    }

    $(target).toggleClass(className);

    $(this).toggleClass(className)
      .attr('aria-expanded', $(this).hasClass(className));
  });

  // Dynamically output the current year
  var y = new Date().getFullYear();
  $('.current-year').text(y);

  // Make Bootstrap dropdowns open on hover
  function toggleDropdown(e) {
    const _d = $(e.target).closest('.dropdown'),
          _m = $('.dropdown-menu', _d);
    setTimeout(function() {
      const shouldOpen = e.type !== 'click' && _d.is(':hover');
      _m.toggleClass('show', shouldOpen);
      _d.toggleClass('show', shouldOpen);
      $('[data-toggle="dropdown"]', _d).attr('aria-expanded', shouldOpen);
    }, e.type === 'mouseleave' ? 300 : 0);
  }

  $('body')
    .on('mouseenter mouseleave','.dropdown',toggleDropdown)
    .on('click', '.dropdown-menu a', toggleDropdown);
})
